import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { useSwipeable } from 'react-swipeable'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import RelatedProducts from '../components/Subcomponent/RelatedProducts'


const ProductPage = ({ location, data: { prismicProduct, allPrismicProduct } }) => {

	const { data } = prismicProduct

	let initialyActive = data.featured_variant - 1
	if (typeof location.state !== 'undefined' && typeof location.state.activeVariant !== 'undefined') {
		initialyActive = location.state.activeVariant
	}

	const [variantModal, setVariantModal] = useState(false)
	const [activeColor, setActiveColor] = useState(initialyActive)
	const [activeBackdropColor, setActiveBackdropColor] = useState(data.variant[initialyActive].variant_background_color)

	const [swipeLearned, setSwipeLearned] = useState(false)

	function setColor(i) {
		setVariantModal(false);
		setActiveColor(i);
		setActiveBackdropColor(data.variant[i].variant_background_color);
	}

	const swipeConfig = {
		delta: 10,
		preventDefaultTouchmoveEvent: true,
		trackTouch: true,
		trackMouse: false,
		rotationAngle: 0,
	}
	
	const handlers = useSwipeable({
		onSwipedRight: () => {
			setSwipeLearned(true)
			if (activeColor > 0) {
				setColor(activeColor-1)
			} else {
				setColor(data.variant.length-1)
			}
		},
		onSwipedLeft: () => {
			setSwipeLearned(true)
			if (activeColor < (data.variant.length-1)) {
				setColor(activeColor+1)
			} else {
				setColor(0)
			}
		},
		...swipeConfig,
	})
	
	return (
		<Layout modal={variantModal}>
			<SEO title={data.name.text}/>
			<React.Fragment>
				<section id='productPage'>
					<div className='container'>
						<div className='wrapper'>
							<div className='product-gallery'>
								<div className='center' {...handlers}>
									<div id='swipe-indicator' className={swipeLearned ? 'learned' : ''}></div>
									{data.variant.map((variant, i) => {
										return (
											<img
												className={activeColor === i ? 'active' : ''}
												src={variant.variant_image.url}
												alt={variant.variant_name.text}
											/>
										);
									})}
								</div>
								<div className='product-variants'>
									<div
										className='show-all'
										onClick={event => setVariantModal(true)}
									>
										<div className='icon'></div>
										<small>Összes változat</small>
									</div>
									<ul>
										{data.variant.map((variant, i) => (
											<li
												className={activeColor === i ? 'active' : ''}
												key={i}
												alt={variant.variant_name.text}
												onClick={event => setColor(i)}
											>
												<span style={{backgroundColor: variant.variant_color}}></span>
											</li>
										))}
									</ul>
								</div>
							</div>
							
							<div className='product-content'>
								<div className='product-content-hero'>
									<h1>{data.name.text}</h1>
									<h4>{data.variant[activeColor].variant_name.text}</h4>
									<ul>
										<li>
											<p>Fejátmérő</p>
											<span/>
											<p>{data.flower_diameter} cm</p>
										</li>
										<li>
											<p>Szárhosszúság</p>
											<span/>
											<p>{data.stem_length} cm</p>
										</li>
										<li>
											<p>Vázatartósság</p>
											<span/>
											<p>{data.vase_life} nap</p>
										</li>
									</ul>
								</div>

								<div className='product-content-related'>
									<h4>További kínálatunk</h4>
									<RelatedProducts related={allPrismicProduct}/>
								</div>
							</div>
						</div>
					</div>
					<div
						className='backdrop'
						style={{backgroundColor: activeBackdropColor}}
					/>
				</section>

				<aside
						id='productVariantModal'
						className={variantModal ? 'active' : ''}
					>
						<ul className='variants-list'>
							{data.variant.map((variant, i) => (
								<li
									key={i}
									alt={variant.variant_name.text}
									onClick={event => setColor(i)}
									style={{backgroundColor: variant.variant_background_color}}
								>
									<img src={variant.variant_image.url} />
								</li>
							))}
						</ul>
					</aside>
			</React.Fragment>
		</Layout>
	)
}

export default ProductPage

export const pageQuery = graphql`
	query ($id: String!) {
		prismicProduct(id: { eq: $id }) {
			data {
				name {
					text
				}
				flower_diameter
				featured_variant
				stem_length
        vase_life
				variant {
					variant_name {
						text
					}
					variant_color
					variant_background_color
					variant_image {
						url
					}
				}
			}
		}
		allPrismicProduct(filter: {id: {ne: $id}}) {
			edges {
				node {
					data {
						name {
							text
						}
						featured_variant
						variant {
							variant_image {
								thumbnails {
									thumbnail {
										url
									}
								}
							}
							variant_background_color
						}
					}
					uid
				}
			}
		}
	}
`