import React from "react"
import { Link } from 'gatsby';

const RelatedProducts = ({ related }) => {
  return (
    <ul className="related-products">
      {related.edges.map((product, i) => (
        <li key={i} style={{backgroundColor: product.node.data.variant[product.node.data.featured_variant - 1].variant_background_color}}>
          <Link to={"/product/" + product.node.uid}>
            <img
              src={product.node.data.variant[product.node.data.featured_variant - 1].variant_image.thumbnails.thumbnail.url}
              alt={product.node.data.name.text}
            />
          </Link>
        </li>
      ))}
    </ul>
  )
};

export default RelatedProducts